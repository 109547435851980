<template>
    <div class="edit-intro-box" id="edit-intro-box">
        <title-nav :title="'修改简介'"/>
        <div class="text">
            <img src="../../assets/images/warning.png" alt="" width="25" height="25">
            <span>简介内容不超过150个字符</span>
        </div>
        <textarea v-model="inputVal" placeholder="请输入" ref="textarea" rows="7"
            class="textarea" maxlength="150" @keyup.enter="verifyIdentity"/>
        <div class="login-button" @click="verifyIdentity">
            <van-loading color="white" size="24px" v-show="isLoading"/>
            确认修改
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
import { updateIntro } from '@/api/api'

export default {
    name: 'EditIntro',
    components: {
        TitleNav
    },
    created(){

    },
    mounted(){
        this.$refs.textarea.focus()
        if(this.$store.state.userAgent !== 'Mobile'){
            document.getElementById("edit-intro-box").style.cssText= 'margin-top: 58px';
        }
    },
    data:()=>{
        return {
            inputVal: JSON.parse(localStorage.getItem('userInfo'))[0].intro,
            uid: JSON.parse(localStorage.getItem('userInfo'))[0].uid,
            isLoading:false,
        }
    },
    methods:{
        verifyIdentity(){
            if(this.inputVal && this.inputVal.length <= 150){
                this.isLoading = true
                updateIntro({ uid: this.uid, intro: this.inputVal }).then(()=>{
                    console.log('个人简介更新成功')
                    this.$router.push('/edit-personal')
                    this.isLoading = false
                })
            }
        }
    },
}
</script>

<style scoped lang="scss">
.edit-intro-box {
    background: #fff;
    min-height: 100vh;
    box-sizing: border-box;
    max-width: 625px;
    margin: 0 auto;
    .text {
        text-align: left;
        display: flex;
        align-items: center;
        margin: 10px;
        span {
            margin-left: 10px;
        }
    }
    .textarea {
        width: 100% ;
        font-size: 16px;
        box-sizing: border-box;
        padding: 0 10px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #1E1043;
        outline: 0;
        word-wrap: break-word;
        overflow-x: hidden;
        overflow-y: auto;
    }
    textarea::placeholder {
        font-weight: 400;
        color: rgba(121,114,145,0.6);
        font-family:  -apple-system,BlinkMacSystemFont,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
    }
    textarea{
        border: none;
        resize:none;
        outline:none;
        min-height: 30px;
        padding: 0;
        border: 1px solid #ddd;
    }
    .login-button {
        margin: 30px auto;
        border: 1px solid #ec745b;
        background: #ec745b;
        color: #FFF;
        width: 80%;
        height: 45px;
        line-height: 45px;
        border-radius: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.van-loading {
    position: relative !important;
    left: 0;
    top: 0;
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    font-size: 0;
    vertical-align: middle;
    -ms-transform: none;
}
</style>